import { GetCurrentUserDataDocument } from '~/graphql/generated/graphql';
import { useCurrentUserDataStore } from '@/stores/currentUserData';
import { APOLLO_TOKEN } from '~/constants';

export const useCurrentUserDataQuery = () => {
  const apolloCookie = useCookie(APOLLO_TOKEN);
  const currentUserDataStore = useCurrentUserDataStore();

  const { onResult: onResultCurrentUserData, onError: onErrorCurrentUserData } = useQuery(GetCurrentUserDataDocument, undefined,
    () => ({
      enabled: !!apolloCookie.value,
      fetchPolicy: 'network-only'
    })
  );

  onResultCurrentUserData(({ data }) => {
    currentUserDataStore?.setCurrentUserData(data?.getCurrentUserData ?? null);
  });

  onErrorCurrentUserData(() => {
    currentUserDataStore?.setCurrentUserData(null);
  });
};
