<template>
  <v-btn
    icon
  >
    <v-avatar class="bg-light">
      <Icon
        height="36"
        name="mdi:user"
        width="36"
      />
    </v-avatar>
    <v-menu
      activator="parent"
      eager
      min-width="240px"
      rounded
    >
      <v-card>
        <v-card-text>
          <div class="mx-auto text-center">
            <v-avatar
              class="avatar__activator-background"
            >
              <Icon
                height="36"
                name="mdi:user"
                width="36"
              />
            </v-avatar>
            <h3 v-if="currentUserDataStore?.currentUserData">
              {{ currentUserDataStore?.currentUserData?.firstName }}
              {{ currentUserDataStore?.currentUserData?.lastName }}
            </h3>
            <h3 v-else>
              {{ $t('fullName') }}
            </h3>
            <p
              v-if="currentUserDataStore?.currentUserData"
              class="text-caption mt-1"
            >
              {{ currentUserDataStore?.currentUserData?.email }}
            </p>
            <p
              v-else
              class="text-caption mt-1"
            >
              {{ $t('email') }}
            </p>
            <v-divider class="my-3" />
            <!-- TODO -->
            <!--          <v-btn -->
            <!--            rounded -->
            <!--            variant="text" -->
            <!--          > -->
            <!--            {{ $t('edit-account') }} -->
            <!--          </v-btn> -->
            <!--          <v-divider class="my-3" /> -->
            <v-btn
              rounded
              variant="text"
              @click="logout"
            >
              {{ $t('logout') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-btn>
</template>

<script lang="ts" setup>
import { useCurrentUserDataStore } from '@/stores/currentUserData';

const { onLogout } = useApollo();
const currentUserDataStore = useCurrentUserDataStore();

const logout = () => {
  onLogout();
  navigateTo('/login');
};
</script>

<style lang="scss" scoped>
</style>
